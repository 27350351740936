// src/components/Footer.js
import React from "react";

const Footer = () => {
  return (
    <footer className="footer pt-5 mt-5">
      <div className="container">
        {/* First Row: Logo/Name, Social Media, and Contact Info */}
        <div className="row align-items-center text-center text-md-start">
          {/* Column 1: Logo and Company Name */}
          <div className="col-md-4 d-flex flex-column align-items-center mb-4 mb-md-0">
            <a
              href="/"
              className="footer-logo-link d-flex align-items-center text-decoration-none"
            >
              <img
                src="/images/jd-services-logo.jpg"
                alt="JD Mobile Auto Detailing Logo"
                className="me-2"
                style={{ height: "80px" }}
              />
              <h2 className="company-footer-name font-weight-bolder mb-0">
                JD Mobile Auto Detailing
              </h2>
            </a>
          </div>

          {/* Column 2: Social Media Links */}
          <div className="col-md-4 d-flex flex-column align-items-center">
            <ul className="list-unstyled text-center">
              <li>
                <h3 className="contact-info-header">Social Media</h3>
              </li>
              <li className="mb-2">
                <h3>
                  <a
                    href="https://www.instagram.com/jdservices.auto.detailing"
                    target="_blank"
                    rel="noreferrer"
                    className="social-link"
                  >
                    <i className="fab fa-instagram me-2"></i>{" "}
                    @jdservices.auto.detailing
                  </a>
                </h3>
              </li>
              <li>
                <h3>
                  <a
                    href="https://www.tiktok.com/@jaideepkamboj581"
                    target="_blank"
                    rel="noreferrer"
                    className="social-link"
                  >
                    <i className="fab fa-tiktok me-2"></i> JD Mobile Auto
                    Detailing
                  </a>
                </h3>
              </li>
            </ul>
          </div>

          {/* Column 3: Contact Information */}
          <div className="col-md-4 d-flex flex-column align-items-center contact-info">
            <h3 className="contact-info-header">Contact Us</h3>
            {/* <p className="mb-1">#2947 Babich Street, Abbotsford</p> */}
            <p className="mb-1">
              {" "}
              <a href="tel:16043003774">
              <i class="fa fa-phone me-2"></i> 604-300-3774
              </a>{" "}
            </p>
            <p className="mb-1">
              <a href="tel:16043003850">
              <i class="fa fa-phone me-2"></i> 604-300-3850
              </a>
            </p>
            <p className="mb-1">
              <a href="mailto:jdservicesabby@gmail.com">
              <i class="fa fa-envelope me-2" aria-hidden="true"></i> jdservicesabby@gmail.com
              </a>
            </p>
          </div>
        </div>

        {/* Second Row: Copyright Message */}
        <div className="row">
          <div className="col-12 text-center mt-4">
            <p className="text-dark my-4 text-sm font-weight-normal">
              All rights reserved. Copyright © 2024 JD Mobile Auto
              Detailing
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
