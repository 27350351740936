// src/components/Home.js
import React from "react";
import PageHeader from "./PageHeader";
import GalleryComponent from "./GalleryComponent";
import AreasWeCoverMap from "./AreasWeCoverMap";

// Array of image paths (no need for dimensions, they will be randomized)
const imagePaths = [
  // New Images at the Top
  "/images/gallery-images/PXL_20241017_183022894.webp",
  "/images/gallery-images/PXL_20241017_183054495.webp",
  "/images/gallery-images/PXL_20241017_183106852.webp",
  "/images/gallery-images/PXL_20241017_235312782.webp",
  "/images/gallery-images/PXL_20241017_234651601.webp",
  "/images/gallery-images/PXL_20241017_234722191.webp",
  "/images/gallery-images/PXL_20241017_234842620.PORTRAIT.webp",
  "/images/gallery-images/PXL_20241017_235034445.PORTRAIT.webp",
  "/images/gallery-images/PXL_20241017_235251460.webp",
  "/images/gallery-images/PXL_20241017_235955020.webp",

  // Previous List Randomized
  "/images/gallery-images/PXL_20241011_224352985.webp",
  "/images/gallery-images/PXL_20241013_011341933.webp",
  "/images/gallery-images/PXL_20241005_192305244.webp",
  "/images/gallery-images/PXL_20241013_011459941.webp",
  "/images/gallery-images/PXL_20241010_213122087.webp",
  "/images/gallery-images/Scanned_20240916-2158-25.webp",
  "/images/gallery-images/PXL_20241013_231430940.webp",
  "/images/gallery-images/jd-services-7.webp",
  "/images/gallery-images/PXL_20241013_231457063.webp",
  "/images/gallery-images/PXL_20240922_015924445.webp",
  "/images/gallery-images/PXL_20240922_175255680.webp",
  "/images/gallery-images/Scanned_20240916-2158-13.webp",
  "/images/gallery-images/Scanned_20240916-2158-06.webp",
  "/images/gallery-images/jd-services-8.webp",
  "/images/gallery-images/PXL_20241013_231446381.webp",
  "/images/gallery-images/PXL_20241005_192249989.webp",
  "/images/gallery-images/PXL_20240922_174024749.webp",
  "/images/gallery-images/PXL_20241010_201854526.webp",
  "/images/gallery-images/PXL_20241011_181020343.webp",
  "/images/gallery-images/Scanned_20240916-2158-11.webp",
  "/images/gallery-images/PXL_20240922_173653412.webp",
  "/images/gallery-images/jd-services-9.webp",
  "/images/gallery-images/PXL_20241010_201914666.webp",
  "/images/gallery-images/PXL_20240922_180356851.webp",
  "/images/gallery-images/Scanned_20240916-2158-03.webp",
  "/images/gallery-images/PXL_20240920_161840214.webp",
  "/images/gallery-images/jd-services-1.webp",
  "/images/gallery-images/PXL_20241010_201854526.webp",
  "/images/gallery-images/jd-services-10.webp",
  "/images/gallery-images/PXL_20241017_235955020.webp",
  "/images/gallery-images/1.jpg",
  "/images/gallery-images/2.jpg",
  "/images/gallery-images/3.jpg",
  "/images/gallery-images/4.jpg",
  "/images/gallery-images/5.jpg",
  "/images/gallery-images/6.jpg",
  "/images/gallery-images/7.jpg"
];





// List of cities/areas you cover
// Areas with their corresponding latitudes and longitudes for the map markers
const areas = [
  { name: "Surrey", lat: 49.1913, lng: -122.849 },
  // { name: 'Vancouver', lat: 49.2827, lng: -123.1207 },
  { name: "Abbotsford", lat: 49.0504, lng: -122.3045 },
  { name: "Mission", lat: 49.1327, lng: -122.321 },
  { name: "Chilliwack", lat: 49.1579, lng: -121.9515 },
  // { name: 'Hope', lat: 49.3820, lng: -121.4429 },
  // { name: 'Agassiz', lat: 49.2386, lng: -121.7697 },
  // { name: 'Richmond', lat: 49.1666, lng: -123.1336 },
  { name: "Langley", lat: 49.1044, lng: -122.6604 },
  // { name: 'Coquitlam', lat: 49.2827, lng: -122.7919 },
  // { name: 'Maple Ridge', lat: 49.2193, lng: -122.5984 },
  { name: "Delta", lat: 49.0847, lng: -123.0583 },
  // { name: 'White Rock', lat: 49.0253, lng: -122.8026 },
];

const Home = () => {
  return (
    <>
      <PageHeader
        title="JD Mobile Auto Detailing"
        subtitle="The Detailing Experience That Comes to You"
        backgroundImage="/images/home.webp"
        buttonText="Learn More About Our Services"
        buttonLink="/services"
      />
      <div className="container my-5">
        <div class="page-heading slide-up">
          <h1>Welcome to JD Mobile Auto Detailing</h1>
          <p>
            At JD Mobile Auto Detailing, we take pride in offering the best mobile auto
            detailing services in the area, combining convenience, quality, and
            professionalism. Our goal is to make vehicle care easy and
            stress-free by bringing our expert detailing services right to your
            doorstep — whether you're at home, at work, or anywhere in between.{" "}
          </p>

          <p>
            We understand that your vehicle is not just a mode of transportation
            — it’s an investment, and we’re here to help you protect it. Our
            team of skilled detailers uses the latest techniques, high-quality
            products, and state-of-the-art equipment to deliver showroom-level
            results, ensuring that your car looks and feels brand new, inside
            and out.
          </p>
        </div>

        <br />
        <br />

        <section className="why-choose-section py-5 slide-up">
          <div className="container">
            <div className="row align-items-center">
              <h2 className="section-title text-center">Why Choose Us?</h2>

              {/* Left Column - Content */}
              <div className="col-sm-12 col-md-6 mb-4 mb-md-0">
                <ul className="why-choose-list">
                  <li>
                    <strong>Convenience at Your Doorstep:</strong> No more
                    wasting time driving to and from the shop. We come to you,
                    saving you both time and hassle. Whether you're at home or
                    work, our mobile detailing service ensures your car gets the
                    care it needs without interrupting your day.
                  </li>
                  <li>
                    <strong>Expertise You Can Trust:</strong> With years of
                    experience in auto detailing, our professional team knows
                    how to treat every vehicle with the care and attention it
                    deserves. From luxury cars to family SUVs, we tailor our
                    services to meet the specific needs of your vehicle.
                  </li>
                  <li>
                    <strong>Premium Products:</strong> We use only the
                    highest-quality products that are safe for your vehicle. Our
                    cleaning solutions and tools are designed to achieve the
                    best possible results.
                  </li>
                  <li>
                    <strong>Comprehensive Detailing Services:</strong> Whether
                    you're looking for an exterior wash, interior deep cleaning,
                    paint correction, or ceramic coating, we offer a wide range
                    of services to suit your needs. Our service packages are
                    designed to provide everything from basic maintenance to
                    full restoration.
                  </li>
                  <li>
                    <strong>Affordable, Transparent Pricing:</strong> We believe
                    in providing top-quality service without breaking the bank.
                    Our pricing is competitive and transparent, with no hidden
                    fees. You'll know exactly what you're paying for and what to
                    expect.
                  </li>
                </ul>
              </div>

              {/* Right Column - Image */}
              <div className="col-sm-12 col-md-6 text-center">
                <img
                  src="/images/car-detailing-background.jpg"
                  alt="Mobile Auto Detailing"
                  className="img-fluid rounded"
                />
              </div>
            </div>
          </div>
        </section>

        <br />
        <br />

        <div className="gallery-section slide-up">
          <h2 className="section-title">Gallery</h2>
          <GalleryComponent imagePaths={imagePaths} />
        </div>

        <br />
        <br />

        <div className="areas-we-cover slide-up">
          <AreasWeCoverMap areaMarkers={areas} />
        </div>
      </div>
    </>
  );
};

export default Home;
