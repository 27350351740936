// src/components/Header.js
import React from 'react';

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg position-absolute top-0 z-index-3 w-100 shadow-none my-3 navbar-transparent zoom-down">
      <div className="container">
        <a className="navbar-brand text-white" href="/">
          {/* <img src="/images/jd-services-logo.jpg" alt="logo" /> */}
        </a>
        <button
          className="navbar-toggler shadow-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navigation"
          aria-controls="navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <span className="navbar-toggler-bar bar1"></span>
            <span className="navbar-toggler-bar bar2"></span>
            <span className="navbar-toggler-bar bar3"></span>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navigation">
          <ul className="navbar-nav ms-auto">
          <li className="nav-item">
              <a href="/" className="nav-link menu-item d-flex align-items-center">
                <i className="material-icons menu-icon">home</i>
                <span>Home</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="/services" className="nav-link menu-item d-flex align-items-center">
                <i className="material-icons menu-icon">dashboard</i>
                <span>Services</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="/packages" className="nav-link menu-item d-flex align-items-center">
                <i className="material-icons menu-icon">view_day</i>
                <span>Packages</span>
              </a>
            </li>
            <li className="nav-item">
              <a href="/contact" className="nav-link menu-item d-flex align-items-center">
                <i className="material-icons menu-icon">article</i>
                <span>Contact Us</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
